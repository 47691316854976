import { useState } from 'react'
import { ethers } from 'ethers'
import Quantity from './Quantity'
import Coupons from '../utils/MintCoupons.json'
import Vouchers from '../utils/MintVouchers.json'
import NFT from '../utils/abi.json'
import { contractAddress, mintPhase, signerAddress, MintPriceInEther,Supply,VoucherMintPriceInEther /*gasLimitPerNft*/ } from '../config'
var md5 = require('md5');
const Mint = ({ currentAccount, totalMinted, setTotalMinted }) => {

    // Set state variables
    let tmpAccount = "";
    let RealMintPrice = 0.00;
    const [mintQty, setMintQty] = useState(0)
    const [minting, setMinting] = useState(false)
    const [counter, setCounter] = useState('')
    const [mintmessage, setMintMessage] = useState('')
    

    if (currentAccount !== '') {
      const { ethereum } = window
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum)
        const signer = provider.getSigner()
        const nftContract = new ethers.Contract(
          contractAddress,
          NFT.abi,
          signer
        )
        nftContract.totalSupply().then((data) => { 
          setCounter(String(parseInt(data._hex)));
        })
        provider.getBalance(currentAccount).then((data) => { 
          //console.log(ethers.utils.formatEther(data));
        })
      }
    }

    // If account does not have a valid coupon, return only a notification message
    if(mintPhase === "public"){
        tmpAccount = signerAddress;  
    }else{ 
        tmpAccount = currentAccount;
    }
    if(Vouchers[currentAccount] === undefined){
      RealMintPrice = MintPriceInEther;
    }else{      
      RealMintPrice = VoucherMintPriceInEther;
      if(Vouchers[currentAccount]){
        RealMintPrice = Vouchers[currentAccount];
      }
    }


    //console.log(tmpAccount);
    if (Coupons[tmpAccount] === undefined){
        return (
            <>
            <div className="neoh_fn_nft_cats_small">
          <ul>
          <li>
              <div className="item">
                <h4 className="parent_category_small">Price</h4>
                <h3 className="child_category_small" title="{MintPriceInEther} Ξth">{MintPriceInEther} **Ξth</h3>
              </div>
            </li>
            <li>
              <div className="item">
                <h4 className="parent_category_small">Total Minted</h4>
                <h3 className="child_category_small" title="{counter} / {Supply}">{counter} / {Supply}</h3>
              </div>
            </li>
          </ul>
        </div>
        <div className="buttons" ><button
                type="button"
                disabled=""
                className="neoh_fn_button only_text"
            >Your Wallet is not on the allowlist</button></div>
            </>
        )
        
    }
        
    
    // Retrieve allotted mints from presale coupon data
    const allottedMints = Coupons[tmpAccount].qty

    //console.log(tmpAccount);
    //console.log(Coupons[tmpAccount]);

    // Set available mints based on allotted mints minus total presale mints
    let availableMints = 0;
    if(totalMinted!== undefined){
        availableMints = allottedMints-totalMinted.Mint;
    }else{
        availableMints = allottedMints;
    }
    if(availableMints > Supply - counter){
        availableMints = Supply - counter;
    }
    if(Supply - counter === 0){
        return (
            <>
            <div className="neoh_fn_nft_cats_small">
          <ul>
          <li>
              <div className="item">
                <h4 className="parent_category_small">Price</h4>
                <h3 className="child_category_small" title="{MintPriceInEther} Ξth">{MintPriceInEther} ***Ξth</h3>
              </div>
            </li>
            <li>
              <div className="item">
                <h4 className="parent_category_small">Total Minted</h4>
                <h3 className="child_category_small" title="{counter} / {Supply}">{counter} / {Supply}</h3>
              </div>
            </li>
          </ul>
        </div>
        <div className="buttons" ><button
                type="button"
                disabled=""
                className="neoh_fn_button only_text"
            >This collecton is minted out</button></div>
            </>
        )    
    }
    // Retrieve coupon from presale coupon data
    const coupon = Coupons[tmpAccount].coupon
    

    /**
     * * Mint NFTs
     * @dev Mint function for the mint method from our smart contract
     * @notice The mint function accepts 3 parameters: (mintQt, allotted, coupon) and is payable
     */
    const mintNfts = async () => {
        // Update mint button to disabled and "minting" text
        setMinting(true)

        try {
            const { ethereum } = window
            if (ethereum) {

                // Connect to contract
                const provider = new ethers.providers.Web3Provider(ethereum)
                const signer = provider.getSigner()
                const nftContract = new ethers.Contract(
                    contractAddress,
                    NFT.abi,
                    signer
                )
                // Set option variables
                const totalPrice = String(((RealMintPrice*1000000) * mintQty)/1000000)
                
                provider.getBalance(currentAccount).then((data) => { 
                  if(ethers.utils.formatEther(data) > totalPrice){
                    //console.log('TRUE')
                    setMintMessage('');
                  }else{
                    //console.log('FALSE');
                    setMintMessage(' - You have insufficient funds');
                  }
                  //console.log(ethers.utils.formatEther(data));
                })


                //const gasLimit = String(gasLimitPerNft * mintQty) // optional
                //console.log(totalPrice);
                //value: ethers.utils.parseEther(totalPrice.substring(0,8))
                const options = {
                    value: ethers.utils.parseEther(totalPrice)
                    // gasLimit, // optional
                } 
                const minterhash = md5(coupon.r + '#' + coupon.s + '#' + coupon.v + '#' + currentAccount);       
                // Initiate mint transaction
                let nftTx = await nftContract.mint(mintQty, allottedMints, coupon, minterhash, options)

                // Log the transaction hash (preferably, set this to a variable and display this to the user)
                console.log('Minting....', nftTx.hash)

                // Assign transaction details to a variable (unused, but could be used to display details to user)
                let tx = await nftTx.wait()
                console.log('Minted!', tx)

                // Transaction events (unused, but could be used to display NFTs to user)
                const events = tx.events // Token IDs are located at events[i].args.tokenId._hex
                events.forEach((v,i) => {
                    console.log(String(events[i].args.id._hex))
                })

                // Re-enable mint button
                setMinting(false)
                
                nftContract.totalSupply()
                .then((data) => { 
                  setCounter(String(parseInt(data._hex)));
                })

                // Updated totalMinted state variable
                //nftContract.addressToMinted(currentAccount)
                nftContract.addressToMinted(minterhash)
                .then((data) => {
                  let totalMints = {
                    "Mint": String(parseInt(data._hex))  
                  }
                  setTotalMinted(totalMints)
                })
            } else {
                console.error("Ethereum object doesn't exist!")
                setMinting(false)
            }
        } catch (err) {
            console.error(err)
            setMinting(false)
        }
    }

    return (
        <>
       
        <div className="neoh_fn_nft_cats_small">
          <ul>
          <li>
              <div className="item">
                <h4 className="parent_category_small">Price</h4>
                {
                RealMintPrice < MintPriceInEther ? (
                  <h3 className="child_category_small" title="{RealMintPrice} Ξth"><del>{MintPriceInEther} Ξth</del> {RealMintPrice} Ξth</h3>
                ):(
                  <h3 className="child_category_small" title="{RealMintPrice} Ξth">{RealMintPrice} Ξth</h3>
                ) 
                }
                
                
              </div>
            </li>
            <li>
              <div className="item">
                <h4 className="parent_category_small">Total Minted</h4>
                <h3 className="child_category_small" title="{counter} / {Supply}">{counter} / {Supply}</h3>
              </div>
            </li>
          </ul>
        </div>
            
            
            
        <Quantity
            mintQty={mintQty}
            setMintQty={setMintQty}
            max={availableMints}
        />

        <div className="buttons" ><button
                type="button"
                disabled={(mintQty === 0 || minting) ? true : false}
                onClick={mintNfts}
                className="neoh_fn_button only_text"
            >{minting ? 'Minting...' : 'Mint'}{mintmessage}</button></div> 

        

            
        </>
    );
}

export default Mint;
