import { useState } from 'react'
const Connect = ({ setCurrentAccount,currentAccount }) => {

    const [connecting, setConnecting] = useState(false)

    const connectWallet = async () => {

        // Disable connect button
        setConnecting(true)
        try {
            const { ethereum } = window

            // Check for Metamask
            if (!ethereum) {
                alert('Metamask not detected')
                setConnecting(false)
                return
            }

            const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
            setCurrentAccount(accounts[0])
            setConnecting(false)
        } catch (err) {
            setConnecting(false)
            console.log('Error connecting to metamask', err)
        }
    }

    return (
        <>
            <div className="buttons" ><button
                type="button"
                disabled={connecting}
                onClick={connectWallet}
                className="neoh_fn_button only_text"
            >{connecting ? 'Connecting...' : 'Connect'}</button></div>
        </>
        
    );
}

export default Connect;




