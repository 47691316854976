export const contractAddress = "0xE3782DB6b6F75e07c2b76a537fFaa8CAcCd2bc83" //[0xE3782DB6b6F75e07c2b76a537fFaa8CAcCd2bc83, 0x32aB3739E5Bd3CE186d39d759175CdfCC358a044]
export const activeNetwork = {
    name: "Goerli Network", // [Goerli Network, Mainnet Network]
    chainId: "0x5",  // [0x5, 0x1]
}
export const activePhase = "Mint" // [Mint, Closed]
export const MintPriceInEther = 0.0099
export const VoucherMintPriceInEther = 0.0069
export const gasLimitPerNft = 258000
//export const mintPhase = "allowlist"
export const mintPhase = "public" // [public, closed]
export const signerAddress = "0x512e2e2f8fa706abf865783c6c74232156f0e5d6"
export const Supply = "331"