import { shortAddress } from "../utils/helperFunctions";

const ConnectedAddress = ({currentAccount}) => {
    if (currentAccount === '')
        return <></>
    return ( 
       
            <button
                type="button"
                disabled
                className="neoh_fn_button only_text"
            >{shortAddress(currentAccount,5,-4)}</button>         
      
     );
}
 
export default ConnectedAddress;