import { useState } from 'react'
import { ethers } from 'ethers'
import NFT from '../utils/abi.json'
import { contractAddress,MintPriceInEther,Supply, /*gasLimitPerNft*/ } from '../config'

const Connect = ({ setCurrentAccount,currentAccount }) => {

    const [connecting, setConnecting] = useState(false)
    
    const { ethereum } = window
    if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum)
        const signer = provider.getSigner()
        const nftContract = new ethers.Contract(
          contractAddress,
          NFT.abi,
          signer
        )
    
    
    nftContract.totalSupply()
    .then((data) => { 
    //setCounter(String(parseInt(data._hex)));
    })
    }
    
    const connectWallet = async () => {

        // Disable connect button
        setConnecting(true)
        try {
            const { ethereum } = window

            // Check for Metamask
            if (!ethereum) {
                alert('Metamask not detected')
                setConnecting(false)
                return
            }

            const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
            setCurrentAccount(accounts[0])
            setConnecting(false)
        } catch (err) {
            setConnecting(false)
            console.log('Error connecting to metamask', err)
        }
    }

    return (
        <>
            <div className="neoh_fn_nft_cats_small">
          <ul>
          <li>
              <div className="item">
                <h4 className="parent_category_small">Price</h4>
                <h3 className="child_category_small" title="{MintPriceInEther} Ξth">{MintPriceInEther} Ξth</h3>
              </div>
            </li>
            <li>
              <div className="item">
                <h4 className="parent_category_small">Supply</h4>
                <h3 className="child_category_small" title="{Supply}">{Supply}</h3>
              </div>
            </li>
          </ul>
        </div>
        <div className="buttons" ><button
                type="button"
                disabled={connecting}
                onClick={connectWallet}
                className="neoh_fn_button only_text"
            >{connecting ? 'Connecting...' : 'Connect'}</button></div>
        </>
        
    );
}

export default Connect;




